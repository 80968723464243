<template>
<div class="finish-task">
  <div class="finish-task-top">
    <el-button class="blue-btn" @click="toBack">返回</el-button>
  </div>
  <div class="finish-task-info">
    <div class="task-info-top">
      <div class="info-img">
        <img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2327481397,2766334344&fm=26&gp=0.jpg" alt="">
      </div>
      <div class="info-account">
        <span>周杰伦</span>
        <span>抖音号：191433445</span>
      </div>
    </div>
    <div class="task-info-bottom">
      <div class="info-bottom-left">
        <span>完成教师任务</span>
        <span>63</span>
      </div>
      <div class="info-bottom-right">
        <span>完成企业任务</span>
        <span>120</span>
      </div>
    </div>
  </div>
  <div class="finish-content-list">
    <el-tabs v-model="finishTab" @tab-click="changeFinishTab" class="finish-content-tab">
      <el-tab-pane label="完成教师任务" name="teacherTask">
        <div class="task-page">
          <div class="task-list">
            <div class="list-item">
              <div class="container">
                <div class="item-cover">
                  <img src="https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2853553659,1775735885&fm=26&gp=0.jpg" alt="">
                  <img src="../../../assets/img/image/teacher-task-1.png" class="sign" alt="">
                </div>
                <div class="item-content">
                  <span class="item-title">年年有余越来越好</span>
                  <div class="item-info">
                    <div class="item-author">
                      <div class="item-avatar">
                        <img src="../../../assets/img/image/user_img.png" alt="">
                        <span>教师</span>
                      </div>
                      <span>2021-01-20</span>
                    </div>
                    <div class="item-evaluate">
                      <div class="evaluate-num">
                        参与班级:
                        <span>电商1班</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-status">
                  <span class="view-task">查看任务 ></span>
<!--                  <span class="finish-task">已完成</span>-->
<!--                  <span class="time-out-task">已截止</span>-->
                </div>
              </div>
            </div>
          </div>
<!--          <div class="task-list no-task-list">-->
<!--            <span>暂无数据</span>-->
<!--          </div>-->
        </div>
        <el-pagination
            v-if="teacherTaskList.length > 0"
            class="pages-center"
            :current-page="taskPages.currentPageNum"
            :page-size="taskPages.eachPageNum"
            layout="prev, pager, next, jumper"
            :total="taskPages.total"
            @current-change="taskCurrentChange">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="完成企业任务" name="companyTask">
        2
      </el-tab-pane>
    </el-tabs>
  </div>
</div>
</template>

<script>
export default {
  name: "FinishTask",
  data(){
    return {
      finishTab:'teacherTask',
      teacherTaskList:[],
      taskPages: {
        currentPageNum: 1,
        eachPageNum: 12,
        total: 0
      },
    }
  },
  methods:{
    changeFinishTab(tab){

    },
    taskCurrentChange(val){
      this.taskPages.currentPageNum = val;
      this.getTeacherTask()
    },
    getTeacherTask(){

    },
    toBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.finish-task{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  .finish-task-top{
    display: flex;
    justify-content: flex-end;
    .blue-btn{
      padding: 8px 25px;
      font-size: 14px;
      border-radius: 10px;
    }
  }
  .finish-task-info{
    width: 100%;
    height: 266px;
    background-image: url("./../../../assets/img/image/finishTaskInfoImg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    .task-info-top{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 28px;
      .info-img{
        width: 98px;
        height: 98px;
        border-radius: 50%;
        overflow: hidden;
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      .info-account{
        display: flex;
        flex-direction: column;
        margin-left: 14px;
        span{
          line-height: 1;
          &:nth-child(1){
            font-size: 16px;
            color: #333333;
          }
          &:nth-child(2){
            font-size: 14px;
            color: #999999;
            margin-top: 8px;
          }
        }
      }
    }
    .task-info-bottom{
      margin-top: 56px;
      display: flex;
      justify-content: center;
      .info-bottom-left, .info-bottom-right{
        display: flex;
        flex-direction: column;
        align-items: center;
        span{
          line-height: 1;
          &:nth-child(1){
            font-size: 14px;
            color: #999999;
          }
          &:nth-child(2){
            font-size: 18px;
            color: #333333;
            margin-top: 10px;
          }
        }
      }
      .info-bottom-left{
        margin-right: 166px;
      }
    }
  }
  .finish-content-list{
    display: flex;
    margin-top: 8px;
    width: 100%;
    height: 100%;
    ::v-deep.el-tabs__nav-wrap{
      &:after{
        display: none;
      }
    }
    ::v-deep .el-tab-pane {
      height: 100%;
      background: #fff;
    }
    ::v-deep .el-tabs {
      flex: 1;
      width: 1%;
    }
    ::v-deep.el-tabs{
      height: 100%;
      box-shadow: none;
      border: none;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background: transparent;
    }
    ::v-deep.el-tabs__header{
      border-bottom: none;
      margin: 0;
    }
    ::v-deep.el-tabs__content{
      height: 1%;
      flex: 1;
      padding-top: 20px;
      padding-bottom: 0;
    }
    ::v-deep.el-tabs__header .is-active {
      font-size: 16px;
      font-weight: 400;
      color: #0824ce;
      border-radius: 6px 6px 0 0;
    }
    ::v-deep.el-tabs__item{
      height: 52px;
      line-height: 52px;
    }
    ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
      border: none;
    }
    .finish-content-tab{
      width: 100%;
      ::v-deep .el-tabs__item {
        height: 45px;
        line-height: 45px;
        font-size: 16px;
        font-weight: 400;
        border: none;
        color: #16113F;
      }

      ::v-deep .el-tabs__active-bar {
        background: #9ACC5B;
      }

      ::v-deep .el-tabs__active-bar:before {
        position: absolute;
        top: -13px;
        left: 40%;
        content: '';
        width: 0;
        height: 0;
        border-style: dashed dashed solid dashed;
        border-width: 7px;
        border-color: transparent transparent #9ACC5B transparent;
      }
    }
    .task-page {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      height: 1%;
    }
    .task-list {
      /*margin-top: 20px;*/
      padding-left: 24px;
      margin-right: -20px;
      display: flex;
      flex-wrap: wrap;
      .list-item {
        width: calc(16% - 14px);
        margin: 20px 20px 0 0;
        border-radius: 8px;
        .container {
          width: 100%;
          border: 1px solid #E3E4EC;
          transition: all .25s ease-out;
          border-radius: 8px;
          cursor: pointer;
          .item-cover {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 138px;
            img {
              max-width: 100%;
              max-height: 100%;
            }
            img.sign {
              position: absolute;
              border-radius: 0;
              top: 25px;
              left: 0;
              opacity: .8;
              width: 80px;
            }
          }
          .item-content {
            padding: 10px;
            .item-title {
              display: block;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
            }
            .item-info {
              .item-author {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .item-avatar {
                  display: flex;
                  align-items: center;
                  margin: 10px 0;
                  img {
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                    border-radius: 50%;
                  }
                  span {
                    display: block;
                    width: 50px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
              .item-evaluate {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;
                i {
                  color: #F19149;
                  margin-right: 2px;
                }
              }
            }
          }
          .item-status {
            height: 38px;
            line-height: 38px;
            text-align: center;
            margin: 0 10px;
            border-top: 1px solid #E5E5E5;
            .view-task {
              color: #2338E6;
            }
            .finish-task {
              color: #20C997;
            }
            .time-out-task {
              color: #666666;
            }
          }
        }
      }
    }
    .no-task-list {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .pages-center {
      margin-bottom: 10px;
    }
  }
}
</style>